.main__container {
	width: 100vw;
	min-height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
