.meta__container {
	width: 100%;
	text-align: justify;
}

.meta__container > * {
	line-height: var(--font-line-height-large);
}

.meta__warning {
	color: var(--color-primary);
	margin-right: 0.5rem;
	position: relative;
	top: 0.15em;
}

@media screen and (max-width: 768px) {
	.meta__container {
		text-align: left;
	}
}
