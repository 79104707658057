@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-RegularOblique.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-MediumOblique.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-DemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-DemiBoldOblique.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-BoldOblique.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-ExtraBoldOblique.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Integral';
	src: url('../fonts/IntegralCF-HeavyOblique.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-SemiBold.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
