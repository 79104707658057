.switcher {
	height: 0.6rem;
	width: calc(100vw / 50);
	margin: 0;
	padding: 0;
	background-color: blue;
	cursor: pointer;
	transition: 0.23s ease-in-out;
}

.switcher:hover {
	height: 2rem;
}
