.links__container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: var(--spacing-large);
	padding-right: var(--spacing-large);
	position: fixed;
	left: 0;
	width: 100vw;
	top: calc(var(--spacing-large) + 1rem);
	align-items: center;
	box-sizing: border-box;
	z-index: 10000;
	transition: 0.4s;
}

.links__container--hidden {
	opacity: 0;
	filter: blur(2px);
}

.links__container * {
	cursor: pointer;
}

.links__github {
	font-size: var(--font-size-medium);
	opacity: 0.3;
	transition: 0.2s ease-in-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--spacing-medium);
}

.links__github p,
.links__github a {
	font-size: var(--font-size-small);
}

.links__container a {
	text-align: right;
}

.links__github:hover {
	opacity: 1;
}
