.abstract__container {
	display: grid;
	grid-template-columns: 1fr;
}

.abstract__container > * {
	line-height: var(--font-line-height-large);
	font-weight: 400;
	text-align: justify;
}

@media screen and (max-width: 768px) {
	.abstract__container > * {
		text-align: left;
	}
}
