.heading__container {
	min-width: 50vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	position: relative;
}

.heading__subcontainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.heading__lead {
	position: fixed;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-medium);
	transition: 0.4s;
}

.heading__lead--hidden {
	opacity: 0;
	filter: blur(2px);
}

.lead__main {
	position: relative;
}

@media screen and (max-width: 768px) {
	.heading__container {
		min-width: 90vw;
	}
}

@media screen and (max-width: 1000px) {
	.heading__container {
		min-width: 75vw;
	}
}
