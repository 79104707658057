.colorswitch__container {
	width: 100vw;
	min-height: 1rem;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	display: flex;
	flex-direction: row;
}

.hint {
	position: fixed;
	left: 0;
	top: 2rem;
	z-index: 0;
	width: 100vw;
	min-height: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	transition: 0.4s;
	transition-delay: 0.25s;
}

.hint__lead--hidden {
	opacity: 0;
	filter: blur(2px);
}

@media screen and (max-width: 768px) {
	.colorswitch__container {
		display: none;
	}
}
