* {
	font-family: 'Roboto', sans-serif;
	cursor: default;
}

*::selection {
	background-color: var(--color-primary);
	color: white;
}

h1 {
	font-size: var(--font-size-large);
	font-family: 'Integral', serif;
	white-space: nowrap;
}

h2 {
	font-size: var(--font-size-medium);
	font-family: 'Integral', serif;
	white-space: nowrap;
}

h3 {
	font-size: var(--font-size-small);
	font-family: 'Integral', serif;
	font-weight: 200;
	white-space: nowrap;
}

hr {
	width: 100%;
	border-width: 0.35rem;
	border-color: var(--color-gray-400);
	border-style: solid;
	background-color: var(--color-gray-400);
}

a {
	color: var(--color-primary);
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	opacity: 0.6;
}
