:root {
	--result-columns: 2fr 6fr;
}

.result__container {
	display: grid;
	grid-template-columns: var(--result-columns);
	grid-auto-rows: max-content;
	width: 100%;
	grid-column-gap: var(--spacing-huge);
	opacity: 0.15;
	transition: 0.5s;
	filter: blur(5px);
}

.result__container--inview,
.result__container:hover {
	opacity: 1;
	filter: blur(0px);
}

.result__percentage {
	font-size: 3rem;
	font-weight: bold;
}

.result__content {
	line-height: var(--font-line-height-large);
	font-weight: 400;
	text-align: justify;
}

.result__percentage--embedded {
	display: none;
	grid-column-start: 1;
	grid-column-end: 3;
	flex-direction: row;
	justify-content: space-between;
}

@media screen and (max-width: 768px) {
	.result__percentage {
		display: none;
	}

	.result__percentage--embedded {
		display: flex;
		align-items: baseline;
		margin-bottom: 1rem;
		color: var(--color-gray-800);
	}

	.result__percentage--embedded h1 {
		color: black;
	}

	.result__content {
		grid-column-start: 1;
		grid-column-end: 3;
		text-align: left;
	}
}
